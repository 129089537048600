export default {
  "public": {
    "product_service": "Product Service",
    "se_overseas_warehouse": "SE Warehouse",
    "se_finance": "SE Finance",
    "se_tracking": 'Tracking number inquiry',
    "law": "Law",
    "privacy_policy": "Privacy Policy",
    "terms_of_use": "Terms Of Use",
    "se_logistics": "SE Logistics",
    "developer_documentation": "Developer Documentation",
    "API_technical_documentation": "API Documentation",
    "cooperation": "Cooperation",
    "partner_program": "Cooperating Partner Program",
    "business_cooperation": "Business Cooperation",
    "about_us": "About Us",
    "privacy": "Privacy Policy",
    "home_page": "Home Page",
    "our_services": "Our Service",
    "learn_more": "Learn More",
    "more": "更多",
    "API_interface": "API Interface",
    "core_advantages": "Core Advantages",
    "visual_operation": "Visualized Operation",
    "easy_management": "Easy Management",
    "safe_and_reliable": "Safe And Reliable",
    "quick_response": "Quick Response",
    "our_motivation": "Our Motivation",
    "our_values": "Our Values",
    "mission": "Mission",
    "vision": "Vision",
    "target": "Target",
    "service_text_01": "International and local shipping all supported, go with advantages.",
    "service_text_02": "Dual-Warehouse,all dimension and weight supported.Flexible management by carton and piece,optimizing cost.",
    "service_text_03": "Credit payment,storage pledge、disbursements for procurement.Multiple Supply-chain Finance services energized globally growth.",
    "api_interface_text": "Abundant API interfaces, easily link to us based on your current ERP systems.",
    "about_banner_title": "Make cross-border sales easier.",
    "about_banner_text_01": "World changes from day to day, the transformation of selling comes more faster and fiercely.",
    "about_banner_text_02": "Global sales channel become more and more broken and diversiform,only the circulation and delivery of cargo remain unchanged. Saleasy.com dedicates to build a low-cost and easily using basic system, to become an important basic facility for global cross-border sales.",
    "about_banner_text_03": "From shipping carrier to airline, combine all shipping capacity,controllable coordinating.Shipping price and time,compensation is taken care by SE Logistics, safe and worry-free.",
    "about_banner_text_04": "Combine local transportation resources and SE Warehouse to allow you to use local logistics as domestic one.",
    "motivation_text_01": "Technology plays a core role, build basic facilities and system applications for cross-border sales，make cross-border sales easier.",
    "motivation_text_02": "Basic facilities that energized cross-border enterprises to develope without border limits, assisting clients developing globally.",
    "motivation_text_03": "Link manufacturers,retail sellers and consumers, to achieve win-win results with global opportunities.",
    "modular_card_title_01": "Stored by carton, one carton one code,fine management for each carton.",
    "modular_card_title_02": "Local distribution, all weights supported, multiple qualified distribution solution options.",
    "modular_card_text_01": "Applicable for storage and distribution for Amazon,MercadoLibre and other platforms，and B2B orders.",
    "modular_card_text_02": "Applicable for storage and distribution for Shopify, dependent website and Tiktok retail orders.",
    "advantages_text_01": "Transparent data of cargo dimension and weight. Checking pictures of inbound and exception product in real time.",
    "advantages_text_02": "Simply and friendly User Interface. Easily control all inventory. Real-time update for procedure status.",
    "advantages_text_03": "Managing with barcodes, warehouse full-scope scanning，full tracking，secure inventory with all pieces.",
    "advantages_text_04": "Fulfillment warehouse instructions in 36 hours, ,make you one step ahead others.",
    "advantages_text_05": "Security secured, compensation for any loss.",
    "advantages_text_06": "Timly delivered, compensation for delay.",
    "advantages_text_07": "All way visualized tracking.",
    "advantages_text_08": "Simply place order, daily updated price.",
    "core_advantages_title_01": "All way secured price.",
    "core_advantages_title_02": "Shipping and delivery time guarantee.",
    "core_advantages_title_03": "Real-time tracking.",
    "core_advantages_title_04": "Place order with one button.",
    "se_warehouse_type_1": "Storage Warehouse",
    "se_warehouse_type_2": "Distribution Warehouse",
    "card_subtitle_1": "Courier",
    "card_subtitle_2": "Air-courier",
    "card_subtitle_3": "Ocean-courier",
    "card_subtitle_4": "Ocean-trucking",
    "card_subtitle_5": "FCL",
    "card_subtitle_6": "Chartered Flight",
    "card_subtitle_7": "Logisitics",
    "card_subtitle_8": "Trucking",
    "card_title_1": "International Shipping",
    "card_title_2": "Local Transportation",
    "register": "Sign In",
    "internal_test": "In Beta",
    "about_saleasy": "关于赛易",
    "about_saleasy_text": "赛易(深圳)供应链科技有限公司是一家以技术为核心，以行业强大底层资源为输出的创新平台型电商服务企业。核心团队成员多数来自UPS、马士基、Paypal等行业头部企业，拥有超过10年甚至20年的跨境物流及电商行业经验。赛易致力于打造行业标杆型电商基础服务体系，为广大从事跨境电商销售的企业提供优质的国际物流、海外仓储及配送的一站式解决方案，让跨境销售更简单。",
    "email": "电子邮箱",
    "contact_address": "联系地址",
    "open_platform": "开放平台",
    "tracking_number": "Tracking number",
    "please_enter_the_tracking_number": "Please enter the tracking number",
    "search_tracking_text": "Please enter the tracking number to inquire~",
    "tracking_empty_text": "No data found~"
  }
}